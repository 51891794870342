@use '../../shared/styles/config.scss' as config;
@use '../../../node_modules/breakpoint-slicer' as bs with (
  $slices: config.$slices
);
@import '../../shared/styles/variables.scss';
@import '../../shared/styles/animations.scss';

.hero-content {
  height: calc(100vh - getRemFromPx(144) - getRemFromPx(124));
  padding: 0 var(--hero-side-padding);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: getRemFromPx(500);

  &--animated {
    opacity: 1;
    animation-name: fade-in-opacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
  }

  &__animation {
    position: absolute;
    max-height: 100%;
    max-width: 100%;
    z-index: 0;
    text-align: right;
    opacity: 0.3;
    transition: all 0.5s ease-in-out;
    top: 0;
    bottom: 0;
    right: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;

    @include bs.from(large) {
      opacity: 1;
    }
  }

  .hero-content-info {
    z-index: 1;

    &__title {
      color: #ffffff;
      font-size: getRemFromPx(65);
      font-weight: 600;
      letter-spacing: getRemFromPx(-0.71);
      text-shadow: 0 0 getRemFromPx(8) rgba(255, 255, 255, 0.5);
      margin-bottom: getRemFromPx(30);
    }

    &__subtitle {
      color: #ffffff;
      font-size: getRemFromPx(25);
      line-height: getRemFromPx(35);
      letter-spacing: getRemFromPx(-0.28);
      padding: getRemFromPx(15) 0;

      &--highlighted {
        color: $pink;
      }
    }

    &__button {
      margin-top: getRemFromPx(67);
      min-width: getRemFromPx(163);

      .button {
        width: fit-content;
      }
    }
  }
}
