@use '../../../shared/styles/config.scss' as config;
@use '../../../../node_modules/breakpoint-slicer' as bs with (
  $slices: config.$slices
);
@import '../../styles/variables.scss';

.button {
  font-size: getRemFromPx(15);
  font-weight: 600;
  letter-spacing: getRemFromPx(-0.40);
  line-height: getRemFromPx(18);
  border-radius: getRemFromPx(6);
  padding: getRemFromPx(8) getRemFromPx(12);
  text-align: center;
  cursor: pointer;
  width: max-content;
  pointer-events: auto;

  @include bs.from(small) {
    padding: getRemFromPx(12) getRemFromPx(33);
  }

  &--default {
    background-color: #ffffff;
    color: $dark-grey;
    border: getRemFromPx(1) solid #ffffff;
    box-shadow: 0 getRemFromPx(2) getRemFromPx(10) 0 rgba(255, 255, 255, 0.5);
  }

  &--main {
    background-color: $pink;
    font-size: getRemFromPx(20);
    letter-spacing: getRemFromPx(-0.60);
    line-height: getRemFromPx(22);
    color: #ffffff;
    font-weight: 400;
    box-shadow: inset 0 getRemFromPx(1) getRemFromPx(3) 0 #c94374,
      0 getRemFromPx(4) getRemFromPx(30) 0 rgba(251, 99, 155, 0.5);
    min-width: getRemFromPx(70);
    padding: getRemFromPx(19);

    @include bs.from(small) {
      padding: getRemFromPx(19) getRemFromPx(45);
    }

    @include bs.from(small-medium) {
      padding: getRemFromPx(19) getRemFromPx(85);
    }
  }

  &--secondary {
    background-color: transparent;
    color: #ffffff;
    border: getRemFromPx(1) solid #ffffff;
  }
}
