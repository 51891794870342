@use '../../../shared/styles/config.scss' as config;
@use '../../../../node_modules/breakpoint-slicer' as bs with (
  $slices: config.$slices
);
@import '../../../shared/styles/variables.scss';

.main-footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include bs.from(medium) {
    flex-wrap: nowrap;
  }

  &__logos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.main-footer-links {
  padding: 0 getRemFromPx(35) 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: getRemFromPx(20);

  @include bs.from(medium) {
    margin-bottom: 0;
  }

  &__link {
    color: $lighter-grey1;
    font-size: getRemFromPx(16);
    font-weight: 600;
    letter-spacing: getRemFromPx(-0.44);
    line-height: getRemFromPx(18);
    text-decoration: none;
    margin-bottom: getRemFromPx(26);
    cursor: pointer;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.main-footer-logos {

  @include bs.from(large) {
    max-width: 66%;
  }

  @include bs.from(large-xlarge) {
    max-width: 56%;
  }

  @include bs.from(xxlarge) {
    max-width: 49%;
  }

  &__images {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: getRemFromPx(20);

    @include bs.from(medium) {
      justify-content: flex-end;
    }

    & > * {
      flex-shrink: 1;
      margin-bottom: getRemFromPx(22);
      margin-right: getRemFromPx(22);
    }
  }

  &__text {
    color: $light-grey2;
    font-size: getRemFromPx(16);
    letter-spacing: getRemFromPx(-0.44);
    line-height: getRemFromPx(12);
    text-align: right;
    margin-right: getRemFromPx(22);
  }
}

.main-footer-logos-images {
  img {
    width: 100%;
    height: 100%;
  }

  &__container-qualiopi {
    max-width: getRemFromPx(160);
  }

  &__text {
    color: #FFFFFF;
    font-size: 10px;
    margin-top: 10px;
    line-height: 14px;
  }

  &__highlighted-text {
    color: $red;
  }

  &__qualiopi {
    height: getRemFromPx(65);
  }

  &__ugap {
    max-height: getRemFromPx(65);
    max-width: getRemFromPx(152);
  }

  &__gar {
    max-height: getRemFromPx(65);
    max-width: getRemFromPx(162);
  }

  &__el {
    max-height: getRemFromPx(65);
    max-width: getRemFromPx(152);
    margin-right: 0;
  }

  &__eu {
    max-height: getRemFromPx(65);
    max-width: getRemFromPx(83);
  }

  &__idf {
    max-height: getRemFromPx(65);
    max-width: getRemFromPx(162);
  }

  &__prvf {
    max-height: getRemFromPx(65);
    max-width: getRemFromPx(116);
  }
}
