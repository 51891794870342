@use '../../shared/styles/config.scss' as config;
@use '../../../node_modules/breakpoint-slicer' as bs with (
  $slices: config.$slices
);
@import '../../shared/styles/variables.scss';

.references-slide {
  &__introduction {
    padding: var(--section-padding);
    transition: padding 0.5s ease-in-out;

    @include bs.from(medium) {
      padding-left: var(--languages-padding-left);
      padding-right: var(--languages-padding-right);
    }
  }

  .references-tabs-container {
    margin: var(--section-padding);
    padding: 0;
    transition: padding 0.5s ease-in-out;
    width: calc( 100vw - var(--section-padding-left) - var(--section-padding-right));
  }
}
