@use '../../../shared/styles/config.scss' as config;
@use '../../../../node_modules/breakpoint-slicer' as bs with (
  $slices: config.$slices
);
@import '../../styles/variables.scss';

.title {
  color: $dark-grey;
  font-size: getRemFromPx(26);
  line-height: getRemFromPx(32);
  font-weight: 600;
  letter-spacing: getRemFromPx(-0.93);
  text-align: center;
  margin-bottom: getRemFromPx(40);

  @include bs.from(medium) {
    font-size: getRemFromPx(34);
    line-height: getRemFromPx(42);
  }
}
