@use '../../styles/config.scss' as config;
@use '../../../../node_modules/breakpoint-slicer/' as bs with (
  $slices: config.$slices
);
@import '../../styles/variables.scss';

.solution-swiper {
  width: 100%;
  padding: getRemFromPx(70) 0 getRemFromPx(35) 0;

  .swiper-slide {
    width: calc(100vw - var(--solution-side-slide-width) * 2 - var(--solution-slide-padding) * 2);
    box-shadow: none;
    background-color: $blue;
    border-radius: getRemFromPx(20);
    align-self: stretch;
    height: auto;

    &--light-blue-color {
      background-color: $light-blue1;

      &.swiper-slide-active {
        box-shadow: 0 getRemFromPx(2) getRemFromPx(30) 0 rgba(0, 0, 0, 0.2);
      }
    }

    &--light-pink-color {
      background-color: $light-pink;

      &.swiper-slide-active {
        box-shadow: 0 getRemFromPx(2) getRemFromPx(30) 0 rgba(0, 0, 0, 0.2);
      }
    }

    &.swiper-slide-active {
      box-shadow: 0 getRemFromPx(2) getRemFromPx(30) 0 rgba(0, 0, 0, 0.3);
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .swiper-pagination-fraction {
    top: getRemFromPx(15);
    color: $grey;
    font-size: getRemFromPx(16);
    letter-spacing: getRemFromPx(-0.17);
    line-height: getRemFromPx(17);
    transform: translateX(-50%);
    left: 50%;
    width: auto;
  }

  .swiper-pagination-current {
    color: $dark-grey;
  }

  .swiper-button-prev-container,
  .swiper-button-next-container {
    top: getRemFromPx(4);
    position: absolute;
    display: flex;
    align-items: center;
  }

  .swiper-button-prev-container {
    right: calc(50% + getRemFromPx(35));
  }

  .swiper-button-next-container {
    left: calc(50% + getRemFromPx(35));
  }

  .swiper-button-prev-container__image,
  .swiper-button-next-container__image {
    width: getRemFromPx(38);
    height: getRemFromPx(38);
    border-radius: getRemFromPx(50);
    background-color: $dark-blue;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }

  .swiper-button-prev-container__image {
    background-image: url('../../../assets/solution-slide/left-arrow.svg');
  }

  .swiper-button-next-container__image {
    background-image: url('../../../assets/solution-slide/right-arrow.svg');
  }

  .swiper-button-prev-container__text,
  .swiper-button-next-container__text {
    display: none;

    @include bs.from(small-medium) {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: calc(100vw / 2 - getRemFromPx(35) - getRemFromPx(38));
      padding: 0 20px;
      text-decoration: underline;
      color: $blue;
      font-size: getRemFromPx(16);
      cursor: pointer;
    }
  }

  .swiper-button-disabled {
    .swiper-button-prev-container__text,
    .swiper-button-next-container__text {
      display: none;
    }

    .swiper-button-prev-container__image,
    .swiper-button-next-container__image {
      cursor: auto;
      opacity: 0.35;
      pointer-events: none;
    }
  }
}
