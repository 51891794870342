@use '../../../shared/styles/config.scss' as config;
@use '../../../../node_modules/breakpoint-slicer/' as bs with (
  $slices: config.$slices
);
@import '../../../shared/styles/variables.scss';

.tabs-container {
  padding: getRemFromPx(5);
  background-color: $light-pink;
  transition: background-color 0.5s ease-in-out;
  border-radius: getRemFromPx(20);
  min-height: calc(( 100vw - var(--section-padding-left) - var(--section-padding-right)) * 0.4);

  @include bs.from(medium) {
    padding: getRemFromPx(17) getRemFromPx(32) getRemFromPx(12) getRemFromPx(32);
  }

  .p-tabview-nav-btn.p-link {
    background: $light-pink;
    box-shadow: none;
    color: $pink;
    transition: background-color 0.5s ease-in-out;

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .p-tabview-nav {
    background-color: $light-pink;
    transition: background-color 0.5s ease-in-out;
    border: none;

    li {
      &.p-highlight {
        .p-tabview-nav-link {
          border-color: transparent;
        }
      }

      .p-tabview-nav-link {
        border: none;
        background-color: $light-pink;
        transition: background-color 0.5s ease-in-out;

        color: $grey;
        border-color: transparent;
        padding: getRemFromPx(20);
        font-size: getRemFromPx(18);
        font-weight: 600;
        letter-spacing: getRemFromPx(-0.20);
        line-height: getRemFromPx(20);
      }

      .p-tabview-nav-link:not(.p-disabled):focus {
        box-shadow: none;
      }

      &:nth-last-child(2) {
        padding-right: getRemFromPx(50);
      }
    }

    li.p-highlight {
      .p-tabview-nav-link {
        background-color: $light-pink;
        transition: background-color 0.5s ease-in-out, color 1s ease-in-out;
        color: $darker-grey;
      }
    }

    li:not(.p-highlight):not(.p-disabled):hover {
      .p-tabview-nav-link {
        background-color: $light-pink;
        transition: background-color 0.5s ease-in-out;
      }
    }

    .p-tabview-ink-bar {
      background-color: $pink;
      bottom: getRemFromPx(10);

      &:before,
      &:after {
        display: block;
        width: 20px;
        height: 6px;
        background-color: $light-pink;
        content: ' ';
        position: absolute;
        top: -2px;
      }

      &::before {
        left: -1px;
      }

      &:after {
        right: -1px;
      }
    }
  }

  .p-tabview-panels {
    background-color: $light-pink;
    transition: background-color 0.5s ease-in-out;
    margin: getRemFromPx(25) getRemFromPx(28) getRemFromPx(55) getRemFromPx(40);
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: visible;
    position: relative;

    .p-tabview-panel {
      overflow: visible;

      &:not(.p-hidden) {
        top: 0;
      }

      &.p-hidden {
        display: block;
        visibility: hidden;
        top: auto;
        position: absolute;
        left: 0;
        right: 0;
      }
    }

    @include bs.from(large) {
      margin-top: getRemFromPx(40);
    }
  }

  &--blue-color {
    background-color: $lighter-blue;

    .p-tabview-nav-btn.p-link {
      background-color: $lighter-blue;
    }

    .p-tabview-nav {
      background-color: $lighter-blue;

      .p-tabview-ink-bar {
        &:before,
        &:after {
          background-color: $lighter-blue;
        }
      }

      li {
        .p-tabview-nav-link {
          background-color: $lighter-blue;
        }
      }

      li.p-highlight {
        .p-tabview-nav-link {
          background-color: $lighter-blue;
        }
      }

      li:not(.p-highlight):not(.p-disabled):hover {
        .p-tabview-nav-link {
          background-color: $lighter-blue;
        }
      }
    }

    .p-tabview-panels {
      background-color: $lighter-blue;
    }
  }

  &--orange-color {
    background-color: $lighter-orange;

    .p-tabview-nav-btn.p-link {
      background-color: $lighter-orange;
    }

    .p-tabview-nav {
      background-color: $lighter-orange;

      .p-tabview-ink-bar {
        &:before,
        &:after {
          background-color: $lighter-orange;
        }
      }

      li {
        .p-tabview-nav-link {
          background-color: $lighter-orange;
        }
      }

      li.p-highlight {
        .p-tabview-nav-link {
          background-color: $lighter-orange;
        }
      }

      li:not(.p-highlight):not(.p-disabled):hover {
        .p-tabview-nav-link {
          background-color: $lighter-orange;
        }
      }
    }

    .p-tabview-panels {
      background-color: $lighter-orange;
    }
  }

  &--yellow-color {
    background-color: $lighter-yellow;

    .p-tabview-nav-btn.p-link {
      background-color: $lighter-yellow;
    }

    .p-tabview-nav {
      background-color: $lighter-yellow;

      .p-tabview-ink-bar {
        &:before,
        &:after {
          background-color: $lighter-yellow;
        }
      }

      li {
        .p-tabview-nav-link {
          background-color: $lighter-yellow;
        }
      }

      li.p-highlight {
        .p-tabview-nav-link {
          background-color: $lighter-yellow;
        }
      }

      li:not(.p-highlight):not(.p-disabled):hover {
        .p-tabview-nav-link {
          background-color: $lighter-yellow;
        }
      }
    }

    .p-tabview-panels {
      background-color: $lighter-yellow;
    }
  }

  &--midBlue-color {
    background-color: $mid-blue;

    .p-tabview-nav-btn.p-link {
      background-color: $mid-blue;
    }

    .p-tabview-nav {
      background-color: $mid-blue;

      .p-tabview-ink-bar {
        &:before,
        &:after {
          background-color: $mid-blue;
        }
      }

      li {
        .p-tabview-nav-link {
          background-color: $mid-blue;
        }
      }

      li.p-highlight {
        .p-tabview-nav-link {
          background-color: $mid-blue;
        }
      }

      li:not(.p-highlight):not(.p-disabled):hover {
        .p-tabview-nav-link {
          background-color: $mid-blue;
        }
      }
    }

    .p-tabview-panels {
      background-color: $mid-blue;
    }
  }
}
