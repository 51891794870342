@import '../../styles/variables.scss';

.timeline-menu-item {
  .timeline-menu-item-target {
    display: flex;
    align-items: center;

    &__dot {
      width: getRemFromPx(13);
      height: getRemFromPx(13);
      border-radius: getRemFromPx(50);
      margin-right: getRemFromPx(7);
      background-color: transparent;
      border: getRemFromPx(1) solid $light-grey;
      opacity: 0.8;
      cursor: pointer;
      transition: opacity 0.5s ease-in-out;
    }

    &__title {
      font-size: getRemFromPx(8);
      font-weight: 600;
      line-height: getRemFromPx(9);
      letter-spacing: getRemFromPx(-0.09);
      color: $light-grey;
      opacity: 0.5;
      cursor: pointer;
      transition: opacity 0.5s ease-in-out;
    }
  }

  .timeline-menu-item-path {
    width: getRemFromPx(15);
    height: getRemFromPx(30);

    &__line {
      height: 100%;
      width: getRemFromPx(1);
      margin: 0 auto;
      background-color: $light-grey;
      opacity: 0.8;
      transition: opacity 0.5s ease-in-out;
    }
  }

  .timeline-menu-item-path--invisible {
    display: none;
  }
}

.timeline-menu-item--active {
  .timeline-menu-item-target {
    &__dot {
      background-color: $dark-grey;
      border: getRemFromPx(1) solid $dark-grey;
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
    }

    &__title {
      color: $dark-grey;
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
    }
  }

  .timeline-menu-item-path {
    &__line {
      background-color: $dark-grey;
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
    }
  }
}
