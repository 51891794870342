@use '../../shared/styles/config.scss' as config;
@use '../../../node_modules/breakpoint-slicer' as bs with (
  $slices: config.$slices
);
@import '../../shared/styles/variables.scss';

.solution-slide {
  &__introduction {
    padding: var(--section-padding);
    transition: padding 0.5s ease-in-out;
    padding-bottom: 0 !important;
  }

  .footer {
    padding: getRemFromPx(50) var(--solution-side-slide-width, 0) getRemFromPx(60)
      calc(var(--solution-slide-padding, 0) + var(--solution-side-slide-width, 0));
    background-color: $dark-blue;
    width: 100%;
  }

  .carousel-container {
    margin-bottom: getRemFromPx(70);

    @include bs.from(large) {
      margin-bottom: getRemFromPx(263);
    }
  }
}
