@use 'sass:string';
@use './shared/styles/config.scss' as config;
@use '../node_modules/breakpoint-slicer' as bs with (
  $slices: config.$slices
);
@import './shared/styles/variables.scss';

:root {
  --section-padding-top: min(7vh, 68px);
  --section-padding-bottom: 1.5%;
  --section-padding-left: 20px;
  --section-padding-right: 20px;
  --section-padding: var(--section-padding-top) var(--section-padding-right) var(--section-padding-bottom)
    var(--section-padding-left);

  --solution-slide-padding: #{getRemFromPx(25)};
  --solution-side-slide-width: #{getRemFromPx(25)};

  --hero-side-padding: 7.8%;

  --xlarge-side-padding: 20vw;

  --languages-padding-left: calc(var(--section-padding-left) + 8vw);
  --languages-padding-right: calc(var(--section-padding-right) + 8vw);

  @include bs.from(medium) {
    --section-padding-left: max(#{getRemFromPx(110)}, 12.3vw);
    --section-padding-right: max(#{getRemFromPx(110)}, 12.3vw);
    --solution-slide-padding: 80px;
    --solution-side-slide-width: 80px;
  }

  @include bs.from(xlarge) {
    --section-padding-left: 13.2vw;
    --section-padding-right: 13.2vw;
    --solution-side-slide-width: max(calc(var(--section-padding-left) - var(--solution-slide-padding)), 80px);
  }
}

html {
  font-size: string.unquote($default-font-size-value + 'px');
  scroll-behavior: smooth;

  font-size: calc(#{$default-font-size-value - 4 + 'px'} + 2 * (100vw / 642));
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
  color: $grey;
  letter-spacing: #{getRemFromPx(-0.44)};
  margin-bottom: #{getRemFromPx(25)};

  &:last-child {
    margin-bottom: 0;
  }
}

em {
  font-style: normal;
  color: $light-blue;
}

section,
.section {
  padding: var(--section-padding);

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }
}