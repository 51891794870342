@use '../../shared/styles/config.scss' as config;
@use '../../../node_modules/breakpoint-slicer' as bs with (
  $slices: config.$slices
);
@import '../../shared/styles/variables.scss';

.animation-3d {
  height: 100%;
  width: 50rem;
  position: relative;
  transform-origin: center;

  #triangle {
    position: absolute;
    right: 2.5rem;
    bottom: calc(50% + 1rem);
    opacity: 0;
    z-index: 55;
    width: 90vw;
    max-width: 33rem;

    @include bs.from(small) {
      width: 100%;
    }
    @include bs.from(large) {
      right: 4.75rem;
    }

    &.triangle-animation {
      transform: translateY(50%) rotate3d(-2.2, 0.2, 0.2, 0.1turn);
      animation: appear, moveUp, rotate;
      animation-duration: 1s, 1s, 2s;
      animation-delay: 0.45s, 1.2s, 2s;
      animation-fill-mode: forwards, forwards, forwards;
    }

    &.triangle-after-animation {
      transform: rotate3d(-0.5, 0.2, 0.2, 0);
      bottom: calc(50% + 2rem);
      opacity: 1;
    }
  }

  #layer1 {
    max-width: 36rem;
    width: 100%;
    transform: rotate3d(-2.2, 0.2, 0.2, 0.1turn);
    overflow: hidden;
    position: absolute;
    right: 0;
    bottom: calc(50% - 0.5rem);

    @include bs.from(large) {
      right: 2rem;
      max-height: 60rem;
    }

    circle {
      transition: transform 500ms ease-in-out;
      &.active {
        transform: scale(1.08) translateY(-10px);
      }
    }

    #dotsVisibilityPath {
      animation: openYourHeart 1s forwards 0.2s;
    }

    &.layer-animation {
      transform: translateY(50%) rotate3d(-2.2, 0.2, 0.2, 0.1turn);
      animation: moveDown, rotate;
      animation-duration: 1s, 1.5s;
      animation-delay: 1.2s, 2.5s;
      animation-fill-mode: forwards, forwards;
    }

    &.layer-after-animation {
      bottom: calc(50% - 1rem);
      transform: rotate3d(-0.5, 0.2, 0.2, 0);
    }
  }

  .cursor {
    position: fixed;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    pointer-events: none;
    left: -100px;
    top: 50%;
    z-index: 10000;
    height: 50px;
    width: 50px;
  }

  @keyframes openYourHeart {
    from {
      r: 0.01px;
    }
    to {
      r: 120px;
    }
  }

  @keyframes moveUp {
    0% {
      bottom: calc(50% + 1rem);
    }

    100% {
      bottom: calc(50% + 2rem);
    }
  }

  @keyframes moveDown {
    0% {
      bottom: calc(50% - 0.5rem);
    }

    100% {
      bottom: calc(50% - 1rem);
    }
  }

  @keyframes appear {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes rotate {
    0% {
      transform: translateY(50%) rotate3d(-2.2, 0.2, 0.2, 0.1turn);
    }

    100% {
      transform: translateY(50%) rotate3d(-0.5, 0.2, 0.2, 0);
    }
  }
}
