@keyframes slide-in {
  0% {
    width: 0px;
  }
  100% {
    width: 100%;
  }
}

@keyframes slide-up {
  from {
    margin-top: getRemFromPx(50);
    opacity: 0;
  }
  to {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes fade-in-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
