@use '../../styles/config.scss' as config;
@use '../../../../node_modules/breakpoint-slicer/' as bs with (
  $slices: config.$slices
);
@import '../../styles/variables.scss';

.timeline-menu {
  opacity: 0;
  position: fixed;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: calc(var(--section-padding-left) / 2);
  transform: translateX(calc(-50%));
  transition: opacity 0.5s ease-in-out;
  z-index: -1;

  @include bs.from(medium) {
    opacity: 1;
    z-index: 1;
  }

  &--invisible {
    opacity: 0;
    z-index: -1;
  }
}
