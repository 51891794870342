@use '../../shared/styles/config.scss' as config;
@use '../../../node_modules/breakpoint-slicer' as bs with (
  $slices: config.$slices
);
@import '../../shared/styles/variables.scss';
@import '../../shared/styles/animations.scss';

.header {
  padding: getRemFromPx(44) var(--hero-side-padding) getRemFromPx(60) var(--hero-side-padding);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;

  &__logo {
    overflow: hidden;

    img {
      width: 90%;
      min-width: 125px;
      max-width: 244px;

      @include bs.from(small) {
        width: auto;
      }
    }
  }

  .p-menu.p-menu-overlay {
    max-height: 50vh;
    overflow: auto;
    transform: translate(-50%, getRemFromPx(10));
    top: 1.8rem !important;
    left: 1rem !important;
  }

  .p-menu .p-menuitem-link {
    padding: getRemFromPx(5);
    justify-content: center;
  }

  .header-action-options {
    display: flex;
    align-items: center;
    z-index: 2;

    &__language-icon {
      margin-right: getRemFromPx(16);
      cursor: pointer;
      display: flex;
      position: relative;

      img {
        width: getRemFromPx(32);
      }

      @include bs.from(small) {
        margin-right: getRemFromPx(37);
      }
    }

    a {
      text-decoration: none;
    }
  }

  &--animated {
    .header__logo {
      height: 44.73px;
      width: 100%;
      animation-name: fade-in-opacity;
      animation-iteration-count: 1;
      animation-timing-function: ease-in;
      animation-duration: 2s;
      display: flex;
      align-items: center;

      a {
        display: flex;
        align-items: center;
      }
    }
  }
}
