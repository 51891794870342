@use 'sass:math';

$dark-blue: #131434;
$blue: #232448;
$light-blue: #4a9ffa;
$light-blue1: #cceafb;
$light-blue2: #72C8F1;
$light-blue3: #4A90E2;
$mid-blue: #f0f5ff;
$lighter-blue: #eff9ff;
$red: #FF0000;
$pink: #e06894;
$light-pink: #ffeff6;
$light-pink1: #EF7C8E;
$lighter-orange: #fff5ef;
$lighter-yellow: #fffbf3;
$mid-grey: #575962;
$darker-grey: #4b4b4b;
$dark-grey: #414553;
$dark-grey1: #6B6B6B;
$light-grey: #c4c6c9;
$light-grey1: #e8e8e8;
$light-grey2: #d2d3e2;
$light-grey3: #E2E2E2;
$light-grey4: #9d9d9d;
$light-blue5: #CDCECF;
$lighter-grey: #f0f0f0;
$lighter-grey1: #f5f5f6;
$grey: #7c7f8b;

$default-font-size-value: 16;

@function getRemFromPx($valueInPx) {
  @return unquote(#{math.div($valueInPx, $default-font-size-value)} + 'rem');
}
