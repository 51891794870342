@import '../../styles/variables.scss';

.slide {
  height: 100%;
  width: 100vw;
  overflow: hidden;

  &--default {
    background-color: #ffffff;
  }

  &--secondary {
    background-color: $dark-blue;
  }
}
