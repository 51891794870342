@use '../../shared/styles/config.scss' as config;
@use '../../../node_modules/breakpoint-slicer' as bs with (
  $slices: config.$slices
);
@import '../../shared/styles/variables.scss';

.custom-modal.contact-us-success {
    .p-dialog-header {
        padding: getRemFromPx(15) getRemFromPx(15) 0 getRemFromPx(15);

        .p-dialog-header-icon {
            width: 4rem;
            height: 4rem;
        }

        .p-dialog-header-icons {
            .pi {
                font-size: 2rem;
            }
        }
    }

    .p-dialog-content {
        padding: 0 getRemFromPx(15) getRemFromPx(38) getRemFromPx(15);
    }
}

.success-message {
    &__image {
        text-align: center;
    }

    &__text {
        text-align: center;
        padding-top: 40px;
        padding-bottom: 20px;
        color: $darker-grey;
        font-size: getRemFromPx(22);
        font-weight: 600;
        letter-spacing: getRemFromPx(-0.28);
        line-height: getRemFromPx(36);
    }

    &__btn {
        margin: 29px 0;
        display: flex;
        justify-content: center;

        .button {
            width: 70%;

            @include bs.from(medium) {
                width: 50%;
            }
        }       
    }
}