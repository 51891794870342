@use '../../shared/styles/config.scss' as config;
@use '../../../node_modules/breakpoint-slicer' as bs with (
  $slices: config.$slices
);
@import '../../shared/styles/variables.scss';

.custom-modal.contact-us-form {
    max-height: 98%;

    .p-dialog-header {
        border-bottom: 1px solid $light-grey3;

        .p-dialog-title {
            text-align: left;
            margin-top: 0;
        }

        .p-dialog-header-icon {
            width: 4rem;
            height: 4rem;
        }

        .p-dialog-header-icons {
            .pi {
                font-size: 2rem;
            }
        }

        @include bs.from(medium) {
            padding: getRemFromPx(16) getRemFromPx(38);
        }
    }

    .p-dialog-content {
        @include bs.from(medium) {
            padding: getRemFromPx(18) getRemFromPx(38) getRemFromPx(38) getRemFromPx(38);
        }
    }

    .form-container-contact {
        &__subtitle {
            color: $darker-grey;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: -0.19px;
            line-height: 24px;
            margin: 20px 0;
        }

        &__btn {
            height: 50px;
            width: 230px;
            border-radius: 25px;
            background-color: $light-blue3;
            color: #FFFFFF;
            font-size: 14px;
            letter-spacing: -0.15px;
            line-height: 17px;
            text-align: center;
            margin-top: 20px;
            box-shadow: none;
        }
    }

    .form-container-contact-field-block {
        margin-bottom: 10px;

        &__error-message {
            .p-error {
                font-size: 12px;
                color: $light-pink1;
                letter-spacing: -0.12px;
                line-height: 15px;
            }
        }

        &__field {
            display: flex;
            flex-direction: column;

            label {
                font-size: 14px;
                font-weight: 600;
                color: $dark-grey1;
                letter-spacing: -0.16px;
                line-height: 20px;
                margin: 5px 10px 10px 0;
                width: 100%;

                @include bs.from(small-medium) {
                    width: 36%;
                }
            }

            input {
                max-width: 220px;
                height: 34px;
                font-size: 14px;
                padding: 1px 15px;
                color: $darker-grey;
                border-radius: 5px;
                background-color: #ffffff;
            }

            textarea {
                color: $darker-grey;
                font-size: 14px;
            }

            .contact-us-select-button {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 30px;
                grid-row-gap: 20px;

                .p-button {
                    width: 140px;
                    height: 36px;
                    border: 1px solid $light-blue3;
                    border-radius: 5px;
                    font-size: 11px;
                    letter-spacing: -0.12px;
                    color: $light-blue3;
                    padding: 10px;

                    &:not(.p-highlight):hover {
                        background-color: #ffffff;
                    }
                }

                .p-highlight {
                    background-color: $light-blue3;
                    color: #ffffff;
                }
            }

            .contact-us-dropdown {
                max-width: 220px;
                min-width: 191px;

                .p-dropdown-label {
                    padding: 0.4rem 0.75rem;
                    color: $darker-grey;
                    font-size: 14px;
                }

                .p-dropdown-item {
                    color: $darker-grey;
                    font-size: 14px;
                }
            }

            @include bs.from(small-medium) {
                align-items: center;
                flex-direction: row;
            }
        }
    }

    .form-container-contact-field-block-field {
        &--full-size {
            flex-direction: column;
            align-items: initial;

            label {
                width: 100%;
            }
        }
    }
}

#type_of_educational_establishment_hidden_input {
    height: 2px;
    z-index: -1;
    width: 2px;
}