@use '../../shared/styles/config.scss' as config;
@use '../../../node_modules/breakpoint-slicer' as bs with (
  $slices: config.$slices
);

@import '../../shared/styles/variables.scss';

.introduction-slide {
  overflow: visible;

  section {
    @include bs.from(medium) {
      padding-left: var(--languages-padding-left);
      padding-right: var(--languages-padding-right);
    }
  }

  &__introduction {
    transition: padding 0.5s ease-in-out;
  }

  .intro-video {
    text-align: center;
    transition: padding 0.5s ease-in-out;

    &__image {
      width: 100%;
      border-radius: getRemFromPx(4);
      box-shadow: 0 getRemFromPx(2) getRemFromPx(40) 0 rgba(0, 0, 0, 0.2);
    }
  }
}
