@import '../../shared/styles/variables.scss';
@import '../../shared/styles/animations.scss';

.hero-footer {
  padding: getRemFromPx(60) 0 getRemFromPx(20) 0;
  text-align: center;

  &--animated {
    opacity: 1;
    animation-name: fade-in-opacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
  }

  &__scroll-image {
    height: 30px;
    cursor: pointer;
  }
}
