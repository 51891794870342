@import '../../shared/styles/variables.scss';

.contact-us {
    p {
        color: $darker-grey;

        &:first-of-type {
            margin-top: 0px;
        }
    }
}

.contact-us-buttons {
    margin-top: getRemFromPx(70);

    &__btn {
        margin: 29px 0;
        display: flex;
        justify-content: center;

        .button {
            width: 75%;
        }
    }
}