@use '../../../shared/styles/config.scss' as config;
@use '../../../../node_modules/breakpoint-slicer/' as bs with (
  $slices: config.$slices
);
@import '../../../shared/styles/variables.scss';

.tab-panel {
  display: flex;
  flex-direction: column;
  height: 100%;

  @include bs.from(medium) {
    flex-direction: row;
  }

  &__introduction {
    width: 100%;

    @include bs.from(medium) {
      width: 50%;
      margin-right: getRemFromPx(60);
    }
  }

  &__video {
    width: 100%;
    margin: getRemFromPx(17) 0;

    @include bs.from(medium) {
      width: 50%;
      justify-content: flex-end;
    }

    @include bs.from(large) {
      margin: getRemFromPx(30) 0;
    }

    video {
      width: 100%;
      height: auto;
      border-radius: getRemFromPx(11);
      box-shadow: 0 getRemFromPx(7) getRemFromPx(30) 0 rgba(0, 0, 0, 0.1);
    }
  }

  @include bs.from(large) {
    display: flex;
  }
}

.tab-panel-introduction {
  &__text {
    p {
      color: $mid-grey;
      margin-bottom: getRemFromPx(10);
      font-family: 'Open Sans', sans-serif;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.tab-panel-introduction-title {
  display: flex;
  margin-bottom: getRemFromPx(30);

  &__image {
    flex-shrink: 0;
    width: getRemFromPx(48);
    height: getRemFromPx(48);
    margin-right: getRemFromPx(16);

    img {
      width: 175%;
      height: 175%;
      left: -37%;
      top: -37%;
      position: relative;
    }
  }

  &__text {
    color: $darker-grey;
    font-size: getRemFromPx(22);
    font-weight: 600;
    letter-spacing: getRemFromPx(-0.28);
    line-height: getRemFromPx(36);

    @include bs.from(medium) {
      font-size: getRemFromPx(26);
    }
  }
}

.tab-panel-video {
  img {
    width: 100%;
    height: 100%;
  }
}
