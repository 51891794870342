@use '../../styles/config.scss' as config;
@use '../../../../node_modules/breakpoint-slicer/' as bs with (
  $slices: config.$slices
);
@import '../../styles/variables.scss';

$breakpoint-from-side-by-side-design: 1100px;

.carousel-slide {
  height: 100%;
  display: flex;
  flex-wrap: wrap;

  padding: 6vw 8vw;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  @include bs.from(medium) {
    padding: getRemFromPx(65) getRemFromPx(65) getRemFromPx(60) getRemFromPx(95);
  }

  @media screen and (min-width: $breakpoint-from-side-by-side-design) {
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  @include bs.from(large) {
    padding: getRemFromPx(63) getRemFromPx(63) getRemFromPx(70) getRemFromPx(96);
  }
}

.carousel-slide-info {
  &__title {
    color: $pink;
    font-size: getRemFromPx(26);
    font-weight: 600;
    letter-spacing: getRemFromPx(-0.71);
    line-height: getRemFromPx(36);
  }

  &__description {
    margin-top: getRemFromPx(25);

    p {
      color: $lighter-grey;
      margin-bottom: getRemFromPx(10);

      &:last-child {
        margin-bottom: 0;
      }
    }

    &--grey-color {
      p {
        color: $mid-grey;
      }
    }
  }

  &__tags {
    margin-top: getRemFromPx(30);
    color: $light-blue2;
    font-size: getRemFromPx(18);
    font-weight: 600;
    letter-spacing: getRemFromPx(-0.49);
    line-height: getRemFromPx(23);

    span {
      margin-right: 15px;
      cursor: pointer;
    }
  }

  @media screen and (min-width: $breakpoint-from-side-by-side-design) {
    width: 45%;
  }
}

.carousel-slide-graphics {
  flex-grow: 1;
  align-self: center;
  justify-self: center;
  width: 100%;
  margin-top: getRemFromPx(10);
  background-image: url('../../../assets/solution-slide/mobile-animation-white-dot.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;

  &__video {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    & > * {
      max-width: 100%;
      max-height: 70vh;

      @media screen and(min-width: $breakpoint-from-side-by-side-design) {
        max-height: initial;
      }
    }

    &--loading {
      video {
        visibility: hidden;
        position: absolute;
      }
    }
  }

  video {
    width: auto;
    height: auto;
    border-radius: 8px;
    border: 2px solid #e06894;
    box-shadow: 0 2px 20px 0 rgba(22, 23, 55, 0.3);
  }

  @media screen and (min-width: $breakpoint-from-side-by-side-design) {
    width: 52%;
    margin-top: 0;
    flex-grow: initial;
    align-self: initial;
    justify-self: initial;
  }

  @include bs.from(large) {
    width: 40%;
  }
}
