@use '../../shared/styles/config.scss' as config;
@use '../../../node_modules/breakpoint-slicer/' as bs with (
  $slices: config.$slices
);
@import '../../shared/styles/variables.scss';
@import '../../shared/styles/animations.scss';

.languages-slide {

  &__introduction {
    padding: var(--section-padding);
    transition: padding 0.5s ease-in-out;

    @include bs.from(medium) {
      padding-left: var(--languages-padding-left);
      padding-right: var(--languages-padding-right);
    }
  }

  .flags-section {
    padding: var(--section-padding);
    padding-left: 0;
    padding-right: 0;
    transition: padding 0.5s ease-in-out;
    flex-wrap: wrap;
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    height: calc(
      (100vw - var(--section-padding-left)  - var(--section-padding-right)) / 7 + getRemFromPx(125)
    );
    overflow: hidden;
    width: 100%;

    @include bs.from(medium) {
      padding-left: calc(var(--languages-padding-left) - 1.5vw);
      padding-right: calc(var(--languages-padding-right) - 1.5vw);
      height: calc(
      (100vw - var(--section-padding-left)  - var(--section-padding-right)) / 7 + getRemFromPx(95)
    );
    }

    @include bs.from(large) {
      height: calc(
      (100vw - var(--section-padding-left)  - var(--section-padding-right)) / 7 + getRemFromPx(75)
    );
    }

    @include bs.from(xlarge) {
      height: calc(
      (100vw - var(--section-padding-left)  - var(--section-padding-right)) / 7 + getRemFromPx(60)
    );
    }

    &__flag {
      width: calc(100% / 7);
    }

    .slide-up-animation__element {
      animation: 0.5s slide-up;
      animation-fill-mode: forwards;
      opacity: 0;
    }

    @for $i from 1 through 10 {
      .slide-up-animation__element:nth-child(#{$i}n) {
        animation-delay: #{$i * 0.5}s;
      }
    }
  }
}
