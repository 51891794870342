@import './shared/styles/variables.scss';

.scrollable-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: min(10vh, getRemFromPx(75)) 0;

  &:first-of-type {
    padding: 0;
    height: 100vh;
    min-height: getRemFromPx(760);
  }

  &:nth-of-type(4) {
    padding-bottom: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }
}
