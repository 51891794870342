@use '../../../shared/styles/config.scss' as config;
@use '../../../../node_modules/breakpoint-slicer' as bs with (
  $slices: config.$slices
);
@import '../../../shared/styles/variables.scss';

.custom-modal {
    width: 95vw;

    .p-dialog-header {
        border-top-right-radius: 11px;
        border-top-left-radius: 11px;
        padding: 15px;

        .p-dialog-title {
            text-align: center;
            color: $darker-grey;
            font-size: getRemFromPx(24);
            font-weight: 600;
            letter-spacing: getRemFromPx(-0.26);
            line-height: getRemFromPx(26);
            margin-top: getRemFromPx(25);
        }

        .p-dialog-header-icons {
            padding: 5px;
        }

        @include bs.from(medium) {
            padding: getRemFromPx(38);
        }
    }

    .p-dialog-content {
        border-bottom-right-radius: 11px;
        border-bottom-left-radius: 11px;
        padding: 15px 20px 30px 20px;

        @include bs.from(medium) {
            padding: 0 getRemFromPx(110) getRemFromPx(60) getRemFromPx(110);
        }
    }

    .c-legal-notices__cookies-table {
        tr {
            &:first-of-type {
                td {
                    font-weight: 600;
                }
            }
        }

        td {
            padding: 5px;
            word-break: break-word;
            width: 33%;

            @include bs.from(small) {
                width: auto;

                &:first-of-type {
                    width: 20%;
                }
            }
        }
    }

    &--bigger {
        height: auto;
        max-height: 95vh;
        width: 95vw !important;

        .p-dialog-header {
            @include bs.from(medium) {
                padding: 15px;
            }
        }

        .p-dialog-content {
            padding: 0 15px 15px 15px;

            @include bs.from(medium) {
                padding: 0 getRemFromPx(38) getRemFromPx(38) getRemFromPx(38);
            }
        }
    }

    @include bs.from(small) {
        width: 75vw;
    }

    @include bs.from(medium) {
        width: 50vw;
    }

    .region-ile-de-france-logo {
        margin: 10px;
        width: 130px;
        vertical-align: middle;
    }
}